// .nav-container {
//   background-color: $primary-color;
//   border-top: 5px solid #fff;
// }
// #topNav {
//   .theme-main-nav {
//     .theme-nav > {
//       .theme-nav-item > {
//         .theme-nav-link:not(x):hover {
//           background: $link-color-darken-10;
//         }
//       }
//     }
//   }
// }
.snFooterContainer {
  background-color: $primary-color;
}



#splash{
  &.user_mode {
      #topNav, #topNavPlaceholder,
      #displayBodyHeader,.snFooterContainer {
          display: none!important;
      }
      .has-mobile-nav body {
        padding-top: 0;
      }
      #panelTwo {
          padding:0!important;
      }
      #accountNav {
          top: 0!important;
      }
      .splash-container {
          margin-top: 0px;
          height: 100vh;
          width: 100vw;
          left: 50%;
          position: fixed;
          transform: translateX(-50%);
          background:white;
          .textBlockElement {
            h3 {
              border-bottom: 3px solid #e85205;
              span {
                color: #046240;
             
              }
            }
          }
  
        .heroPhotoElement img{
           max-width: 500px;
    }
      }
  }
}